<template>
  <div>
    <div class="d-flex flex-row mb-6">
      <div class="display-3">Troubleshooting</div>
      <v-spacer></v-spacer>
      <dev-menu></dev-menu>
    </div>

    <div class="display-1 mb-6">RMR</div>

    <v-card class="container">
      <v-card-title>
        <div class="title">Impossible de se connecter</div>
      </v-card-title>
      <p>
        <b>
          "Help ! on a ce message d'erreur: Une erreur est survenue. Veuillez contactez l'administrateur."
        </b>
      </p>
      <p>Il arrive que le serveur s'endorme (nous n'avons pas d'explication) et dans ce cas l'api ne répond</p>
      <p>La solution est de </p>
      <li>Relancer le service Uvicorn avec une MEP (et pas en ssh en se connectant au server)</li>
      <prism class="ml-5" language="bash">./manual-deploy-prod.sh</prism>
      <div class="ml-5">Sélectionner l'option "4 - Seulement relancer le service Uvicorn." <b></b></div>
      <p>Une fois le déploiement terminé, l'API fonctionne de nouveau et il est possible de se connecter</p>
      <p><i>Note importante: Si le service était endormi, 
        les DAGs Airflow ne se sont pas lancés et Airflow va les lancer directement</i></p>
      <p class="mt-5">
        Autres problèmes
      </p>
      <li>Se connecter en SSH au server de prod:</li>
      <prism class="ml-5" language="bash">ssh reportapi@rmf-report.pilotsystems.net</prism>
      <li>Voir le status du service Uvicorn</li>
      <prism class="ml-5" language="bash">systemctl --user status api.rmr.reworldmediafactory.com.uvicorn.service</prism>
      <li>Consulter un journal de log du service Uvicorn</li>
      <prism class="ml-5" language="bash">journalctl -f --user -u api.rmr.reworldmediafactory.com.uvicorn.service</prism>
      <div class="ml-5">[Ctrl + C] pour arrêter</div>
    </v-card>

    <v-card class="container">
      <v-card-title>
        <div class="title">Voir les logs</div>
      </v-card-title>
      <p>
        Comment voir les logs d'un crawler même s'ils sont en train de tourner ?
      </p>
      <li>Se connecter en SSH au server de prod:</li>
      <prism class="ml-5" language="bash">ssh reportapi@rmf-report.pilotsystems.net</prism>
      <li>Se placer dans le bon répertoire</li>
      <prism class="ml-5" language="bash">cd /home/reportapi/main/logs</prism>
      <li>lister les fichiers</li>
      <prism class="ml-5" language="bash">ls -lah</prism>
      <li>Suivre la fin d'un fichier de log</li>
      <prism class="ml-5" language="bash">tail -2500f 2023-04-25_crawlers.partners.partners_get_and_store_infos_main.log</prism>
      <div class="ml-5">[Ctrl + C] pour arrêter</div>

      <p class="mt-5">
        Sur l'interface d'Airflow : <a target="_blank" href="https://airflow.rmr.reworldmediafactory.com//home">https://airflow.rmr.reworldmediafactory.com//home</a>
      </p>
      <li>Dans la liste on peut déjà voir si un DAG tourne ou a une erreur</li>
      <li>Ne pas hésiter à cliquer sur la task et trouver les logs pour savoir où le script en ai.</li>      
    </v-card>

    <div class="display-1 mb-6">Programmatic</div>

    <v-card class="container">
      <v-card-title>
        <div class="title">Méthode générale</div>
      </v-card-title>
      <v-card-text>
        <p>Les cas spécifique sont décrits dans les sections suivantes.</p>
        <p>
          La plupart du temps on reçoit ce message de détresse de la part du
          pôle Programmatic :
        </p>
        <p>
          <b>
            "Help, il n'y a pas de data pour le partenaire Bob. (pour la journée
            d'hier)"
          </b>
        </p>
        <p>La méthodologie de base:</p>
        <p class="mt-5">
          Avant tout : 
          Vérifier sur l'interface d'Airflow 
          <a target="_blank" href="https://airflow.rmr.reworldmediafactory.com//home">Airflow</a>
          que le DAG du partenaire en question a bien tourné et / ou qu'il n'y a pas d'erreur.
          (s'il y a une erreur, il faut regarder les logs du DAG cf ci-dessus)
        </p>
        <p>Sinon</p>
        <p>
          <li>
            Dans son terminal: Se mettre sur la branche dev ou main et la mettre
            à jour
          </li>
          <prism class="ml-5" language="bash"
            >cd /path/to/my/local/repo/report-api</prism
          >
          <prism class="ml-5" language="bash">git checkout dev</prism>
          <prism class="ml-5" language="bash">git pull</prism>
          <li>
            Eventuellement, récupérer la DB de prod pour la mettre en local
          </li>
          <li>
            Si ce n'est pas déjà fait, créer et activer son environnement
            virtuel python pour report-api
          </li>
          <prism class="ml-5" language="bash">python3 -m venv venv</prism>
          <prism class="ml-5" language="bash">source venv/bin/activate</prism>
          <prism class="ml-5" language="bash"
            >pip install -r requirements.txt</prism
          >
          <prism class="ml-5" language="bash"
            >pip install -r requirements_dev.txt</prism
          >

          <li>Lancer la commande python du crawler pour le partenaire Bob</li>
          <div class="ml-5">Exemple</div>
          <prism class="ml-5" language="bash">
            python -m crawlers partners_get_and_store_infos
            --partners=[code-du-partenaire] --debug
          </prism>
          <div class="ml-5">Pour Bob</div>
          <prism class="ml-5" language="bash">
            python -m crawlers partners_get_and_store_infos
            --partners=bob --debug
          </prism>
          <div class="ml-5"><i>La liste des [code-du-partenaire] est disponible dans le fichier:
          `crawlers/partners/partners_get_and_store_infos.py` > cf
          PARTNER_CLASS_BY_CODE_DICT variable</i></div>
          <li>Mettre des <prism class="ml-5" language="bash">print("variabe", variable); exit("STOP DEV")</prism> partout pour débugger et résoudre le problème</li>
          <li>Une fois le problème résolu, commit et push sur la branche dev, puis sur la branche main :</li>
          <prism class="ml-5" language="bash">git checkout main</prism>
          <prism class="ml-5" language="bash">git pull</prism>
          <prism class="ml-5" language="bash">git merge dev</prism>
          <prism class="ml-5" language="bash">git push</prism>
          <prism class="ml-5" language="bash">git checkout dev</prism>
          <div class="ml-5"><b>Avant de déployer, s'assurer d'avoir tous les fichiers de conf et .env à jour en local.</b></div>
          <div class="ml-8"> 
            - <a href="https://drive.google.com/drive/folders/1HtmB_1IAYKuWAJoHP-hoenWzBXCkvwKo">
              drive.google.com de Data > RMR > report-api
            </a>  : .env.prod <br/>
            - <a href="https://drive.google.com/drive/folders/1l13jvvFOwpTu6I3H2oGDp3O1rwFFeEBQ">
              drive.google.com de Data > RMR > report-api > crawler
            </a> : .env.prod<br/>
            - <a href="https://drive.google.com/drive/folders/1InzEgFDJ5zR5rBr3aRAtWAy9sNeOkdH5">
              drive.google.com de Data > RMR > report-api > crawler > config
            </a> : tous les fichiers
          </div>
          <li>Déployer</li>
          <prism class="ml-5" language="bash">./manual-deploy-prod.sh</prism>
          <div class="ml-5"># sélectionner: 2 - Seulement le code source</div>
          <li>Se connecter en SSH au server de prod:</li>
          <prism class="ml-5" language="bash">ssh reportapi@rmf-report.pilotsystems.net</prism>
          <li>Se placer dans le bon répertoire</li>
          <prism class="ml-5" language="bash">cd /home/reportapi/main</prism>
          <li class="mt-3"><b>Lancer le crawler des partners</b></li>
          <prism class="ml-5" language="bash">/home/reportapi/main/.venv/bin/python -m crawlers partners_get_and_store_infos --partners=bob</prism>
          <li>Lancer le script qui compute les data partenaire pour le RMR</li>
          <prism class="ml-5" language="bash">/home/reportapi/staging/.venv/bin/python -m scripts.compute_partners_infos -n 1</prism>
          <div class="ml-5 mt-5">Par défault ces scripts se lance pour les data de la veille.</div>
          <div class="ml-5">Imaginons que nous sommes le 20 Avril 2023 et que nous voulons récupérer les data du 12 au 15</div>
          <li>Pour lancer le crawler pour une période précise</li>
          <prism class="ml-5" language="bash">/home/reportapi/main/.venv/bin/python -m crawlers partners_get_and_store_infos --partners=bob --from=2023-04-12 --to=2023-04-15</prism>
          <li>Ensuite lancer le compute pour une période précise, n'est pas possible</li>
          <div class="ml-5">Il ne prend en paramètre que le nombre de jour entre le premier jour à compute et aujourd'hui.</div>
          <div class="ml-5">Entre le 12 et aujourd'hui il y a 8 jours, afin de se faciliter le compte il est possible d'utiliser ce petit outils: 
            <a target="_blank" href="https://rmr.reworldmediafactory.com/dev/n-days">https://rmr.reworldmediafactory.com/dev/n-days</a>
          </div>
          <div class="ml-5">Ici il faut relancer le script compute en utilisant -n 8</div>
          <prism class="ml-5" language="bash">/home/reportapi/staging/.venv/bin/python -m scripts.compute_partners_infos -n 8</prism>
        </p>
      </v-card-text>
    </v-card>
    <v-card class="container mt-4">
      <v-card-title>
        <div class="title">Les rapports par email: Viously et Inskin</div>
      </v-card-title>
      <v-card-text>
        <p>
          <b>
            "Help, il n'y a pas de data pour le partenaire Viously et/ou Inskin (pour les 4 derniers jours)"
          </b>
        </p>
        <p>2 possibilités: </p>
        <p>
          <div class="">
            1/ Les emails n'ont pas été reçus le jour même avant le passage du script
          </div>
          <ul>
            <li>=> Vérifier que les emails sont bien arrivés dans la boite mail rmr@reworldmedia.com</li>
          </ul>
          <ul>
            <li>=> Vérifier que les token Azure pour accéder à rmr@reworldmedia.com ne sont pas obsolète (à renouveller chaque année avec le service informatique)</li>
          </ul>
          <!--div class="mt-3">
            2/ Le token gmail est périmé, le script n'a donc plus accès à la boite mail.
          </div>
          <ul>
            <li>Créer/Utiliser une sessions chrome connectée au compte Gmail de Thibault (tfuchez@reworldmedia.com) pour la suite:</li>
            <li>Aller ici <a target="_blank" href="https://rmr.reworldmediafactory.com/admin/partner/account/list">https://rmr.reworldmediafactory.com/admin/partner/account/list</a> </li>
            <li>En haut à droite de la page, ouvrir le menu en pointillé et cliquer sur "<a target="_blank" href="https://rmr.reworldmediafactory.com/partner/gmail-token-helper">Gmail token helper</a>"</li>
            <li>Si le status du token est "EXPIRED" Cliquer sur le bouton "TRY REFRESH EXPIRED TOKEN(S)"</li>
            <li>Si le status est "NOT_REFRESHABLE" Cliquer sur le bouton "GET NEW TOKEN"</li>
            <li>Suivre le lien et la procédure expliquée pour rércupérer le code permettant d'avoir un nouveau token valide</li>
            <li>Une fois le token VALID, se connecter au server de prod et relancer le script crawler comme expliqué ci-dessus dans la partie "Méthode générale"</li>
          </ul-->
        </p>
      </v-card-text>
    </v-card>
    <v-card class="container mt-4">
      <v-card-title>
        <div class="title">Nouveau Partner mis en prod</div>
      </v-card-title>
      <v-card-text>
        <p>
          <b>
            "Help, il n'y a pas de data pour le nouveau partenaire Bob dans le Dashboard RMR"
          </b>
        </p>
        <p>
          <div class="">
            Quand un nouveau partnenaire est ajouté et qu'il est mis en prod, il faut ensuite plusieurs choses
          </div>
          <div class="">
            Au niveau de MYSQL en prod, il doit être fait:
          </div>
          <ul>
            <li>L'insert du partenaire dans la table partner a du être fait en premier, pour avoir l'id à mettre dans la classe python</li>
            <li>Le create table partner__bob</li>
            <li>L'insert des partner_dimension_type s'il y en a</li>
          </ul>
          <div class="">
            Dans <a href="https://rmr.reworldmediafactory.com/admin/partner/account/list" target="_blank">
              https://rmr.reworldmediafactory.com/admin/partner/account/list
            </a>
          </div>
          <ul>
            <li>Activer le nouveau partenaire</li>
            <li>Ajouter le nouveau Compte partenaire et à l'activer (au niveau de l'édition du partner account).</li>
          </ul>
          <div class="mt-3">
            Ensuite les data ne peuvent pas remonter dans le dashboard RMR tant que le script compute a besoin des sites matching. <br/>
            Mais il ne peut pas y avoir de site matching sans data dans la table partner__bob.
          </div>
          <li>Lancer une fois le script crawler partner pour Bob</li>
          <prism class="ml-5" language="bash">/home/reportapi/main/.venv/bin/python -m crawlers partners_get_and_store_infos --partners=bob --from=2023-04-12</prism>
          
          <li>Aller au <a href="https://rmr.reworldmediafactory.com/premium/sites-matching">Site matching</a> pour matcher tous les sites du partenaire Bob</li>
          <li>Lancer une seconde fois le script crawler partner pour Bob</li>
          <prism class="ml-5" language="bash">/home/reportapi/main/.venv/bin/python -m crawlers partners_get_and_store_infos --partners=bob --from=2023-04-12</prism>
          <div class="ml-5">Relancer le script compute </div>
          <prism class="ml-5" language="bash">/home/reportapi/staging/.venv/bin/python -m scripts.compute_partners_infos -n 8</prism>
          <div class="">
            Il devrait y avoir des data dans le dashboard RMR pour Bob maintenant.
          </div>
          
        </p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import DevMenu from "@/views/menus/DevMenu.vue";
import Prism from "vue-prism-component";
import "prismjs/components/prism-bash";
import "prismjs/components/prism-python";

export default {
  name: "Troubleshooting",
  components: { DevMenu, Prism },
  data() {
    return {
      textareasNumber: 3,
      textareas: {},
    };
  },
  methods: {
    copy(e) {
      let selectedIndex = e.currentTarget.getAttribute("data-index");
      let inputValue = this.textareas[selectedIndex];
      console.log("selectedIndex", selectedIndex);
      console.log("inputValue", inputValue);
      navigator.clipboard.writeText(inputValue).then(
        function () {
          console.log("Async: Copying to clipboard was successful!");
        },
        function (err) {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },
  },
  mounted() {},
  computed: {
    // myComputeFunction() {},
  },
  watch: {
    // isOpenCreateCronDialog(visible) {
    //   if (visible) {
    //     this.identifier = "";
    //     this.$refs.FormCron.resetValidation();
    //   }
    // },
  },
};
</script>
